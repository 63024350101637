/**
 * Init module.
 *
 * @module components/init
 * @see module:main
 *
 * @file init.js
 *
 * Initializes the scripts
 *
 * Imports the javascript modules.
 * Creates new instances of the theme classes.
 *
 * @class      Init
 * @package    brig-square
 * @subpackage brig-square
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

import Common from './common.js'
import { DisableCookies } from './disable-cookies.js'
import { Hamburger } from './hamburger.js'
import { FixHeights } from './fix-heights.js'
import { Map } from './map.js'
import { InView } from './in-view.js'
import { InputFocus } from './input-focus.js'
import 'altcha'

export class Init {
  constructor() {

    new DisableCookies

    // Initialize common variables
    Common.init()

    // Output initialisation message
    if (Common.consoleLogging) console.log('Initializing Brig Square Theme Script')

    new Hamburger
    new FixHeights
    new Map
    new InView
    new InputFocus
  }
}