/**
 * Fix Heights module.
 *
 * @module components/FixHeights
 *
 * @file fix-heights.js
 *
 * Fix Heights Functionality
 *
 * Sets absolute pixel heights on elements to prevent page jumps on mobile
 *
 * @class      FixHeights
 * @package    brig-square
 * @subpackage brig-square
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import $ from 'jquery'

export class FixHeights {
  constructor() {

    this.classList = [
      '.viewport-height-110',
      '.viewport-height-50',
    ]

    this.sizeList = [1.1, 0.5, 0.5, 0.4]
    this.$elements = []

    this.fixHeights()
  }

  fixHeights() {

    // cache the DOM elements
    for (var i = 0; i < this.classList.length; i++) {

      this.$elements[i] = $(this.classList[i])
    };

    $(window).on('resize', () => this.checkResizeX())

    this.resize()
  }

  checkResizeX() {

    var newX = $(window).width()

    if ((newX > 1024) || (this.currentX !== newX)) {
      this.currentX = newX

      this.resize()
    }
  }

  resize() {
    var height = window.innerHeight

    for (var i = 0; i < this.classList.length; i++) {

      this.$elements[i].css('height', height * this.sizeList[i])
    }
  }
}