/**
 * Common module.
 *
 * @module components/common
 * @see module:components/init
 *
 * @file common.js
 *
 * Provides common properties utilized across multiple classes.
 *
 * @class      Common
 * @package    brig-square
 * @subpackage brig-square
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 *
 */

export default class Common {
  static init() {

    // Constants - plugin behaviour
    this.consoleLogging = true
  }
}