/**
 * InView module.
 *
 * @module components/InView
 *
 * @file InView.js
 *
 * InView Functionality
 * Checks if elements are in view and adds a class
 * Applies to elements with a class of 'in-view'
 * Adds a class of 'on-screen' to trigger animation
 *
 * @class      InView
 * @package    peapods
 * @subpackage peapods
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class InView {
  constructor() {
    this.rangePercentage = 0.05

    this.elements = document.querySelectorAll('.in-view')

    this.elementData = []

    if (this.elements.length > 0) this.setup()
  }

  setup() {
    this.elements.forEach((element, index) => {
      this.elementData[index] = {
        dom: element,
        done: false
      }
    })

    document.addEventListener('scroll', () => this.testPosition())

    setTimeout(() => {
      this.testPosition()
    }, 10)
  }

  testPosition() {

    const range = window.innerHeight * this.rangePercentage

    this.elementData.forEach(element => {

      if (!element.done) {

        // Test if any part of the element is in view
        if (element.dom.getBoundingClientRect().top - range < window.innerHeight && element.dom.getBoundingClientRect().bottom + range > 0) {
          element.dom.classList.add('on-screen')
          element.done = true
        }
      }
    })
  }

}
