/**
 * Brig Square javascript module.
 *
 * @module main
 *
 * @file brig-square.js
 *
 * Initiates the necessary javascript
 *
 * Imports the Init module and runs the plugin.
 *
 * @class      BrigSquare
 * @package    brig-square
 * @subpackage brig-square
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import { Init } from './components/init.js';

class BrigSquare {
  constructor() {
    let init = new Init();
  }
}
window.addEventListener('load', function (event) {
  const brigSquare = new BrigSquare();
});