/**
 * Map module.
 *
 * @module components/Map
 *
 * @file Map.js
 *
 * Map Functionality
 *
 *
 * @class      Map
 * @package    brig-square
 * @subpackage brig-square
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */

export class Map {
  constructor() {

    // Quit if map element not present
    if (document.getElementById('map') === null) return

    this.map

    this.token = 'pk.eyJ1IjoicGVhcG9kcyIsImEiOiJja3A0ZnA2d2cwMG1nMnBxZWpxZDluc3V1In0.O0Hce5zs2-mQIQJnoSJeHA'
    this.style = 'mapbox://styles/peapods/ckp5s2jz52icl18mk3p0mndvm'

    this.center = [0.847970, 52.957428]
    this.marker = this.center
    this.zoom = 16

    // Quit if browser unable to handle map
    if (typeof mapboxgl == 'undefined' || !mapboxgl.supported()) {
      return
    }

    this.setupMap()
  }

  setupMap() {

    mapboxgl.accessToken = this.token

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      center: this.center,
      zoom: this.zoom,
      attributionControl: false
    })

    this.addMarker()

    this.touchEvents()
  }

  addMarker() {

    let marker1 = new mapboxgl.Marker({ 'color': '#19324D' })
      .setLngLat(this.marker)
      .addTo(this.map)
  }

  touchEvents() {

    this.map.dragRotate.disable()
    this.map.touchPitch.disable()
    this.map.touchZoomRotate.disable()

    this.map.on('dragstart', (event) => {

      if (this.isTouchEvent(event) && !this.isTwoFingerTouch(event)) {

        this.map.dragPan.disable()
        this.map.touchZoomRotate.disable()
      }
    })

    // Drag events not emited after dragPan disabled, so I use touch event here
    this.map.on('touchstart', (event) => {

      if (this.isTouchEvent(event) && this.isTwoFingerTouch(event)) {

        this.map.dragPan.enable()
        this.map.touchZoomRotate.enable()
        this.map.touchZoomRotate.disableRotation()
      }
    })
  }

  isTouchEvent(e) {
    return e.originalEvent && 'touches' in e.originalEvent
  };

  isTwoFingerTouch(e) {
    return e.originalEvent.touches.length >= 2
  };
}