/**
 * Hamburger module.
 *
 * @module components/Hamburger
 *
 * @file hamburger.js
 *
 * Hamburger Functionality
 *
 *
 * @class      Hamburger
 * @package    Brig Square
 * @author     Daryl Bowers <daryl@peapods.co.uk>
 *
 */
import * as focusTrap from 'focus-trap'

export class Hamburger {
  constructor() {

    this.hamburgerId = '#hamburger'
    this.mobileMenuId = '#custom-menu'
    this.mobileMenu = document.querySelector(this.mobileMenuId)
    this.hamburgerButton = document.querySelector(this.hamburgerId + ' button')
    this.mobileMenuLinks = document.querySelectorAll(this.mobileMenuId + ' a')
    this.openClass = 'nav-menu-open'
    this.minWidth = 1024
    this.mediaQuery = window.matchMedia('(min-width: ' + this.minWidth + 'px)')

    // Set up focus traps
    this.navTrap = focusTrap.createFocusTrap([this.mobileMenuId, this.hamburgerId], {
      onDeactivate: () => this.closeMenu(),
      initialFocus: false
    })

    // Click handler for Hamburger
    this.hamburgerButton.addEventListener('click', () => this.toggleMenu())

    // Click handler for menu links
    this.mobileMenuLinks.forEach(link => link.addEventListener('click', () => this.closeMenu()))

    // Close the nav menu if the window is resized to desktop
    this.mediaQuery.addEventListener('change', e => {
      if (e.matches && !document.body.classList.contains('home')) this.closeMenu()
    })
  }

  toggleMenu() {

    // Close the nav menu if open
    if (document.body.classList.contains(this.openClass)) {
      this.closeMenu()
      return
    }

    // Open the nav menu
    this.openMenu()
  }

  openMenu() {

    document.body.classList.add(this.openClass)
    this.hamburgerButton.setAttribute("aria-expanded", "true")
    this.mobileMenu.setAttribute("aria-modal", "true")

    // Activate trap after a delay to allow the menu to open
    setTimeout(() => this.navTrap.activate(), 100)
  }

  closeMenu() {
    this.navTrap.deactivate()

    document.body.classList.remove(this.openClass)
    this.hamburgerButton.setAttribute("aria-expanded", "false")
    this.mobileMenu.setAttribute("aria-modal", "false")
    this.hamburgerButton.blur()
  }
}
